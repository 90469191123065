<template>
  <div class="container">
    <strong>
      <h5>Manage User</h5>
    </strong>
    <div class="content card-panel">
      <div class="row">
        <div class="col s4">
          <label>Name</label>
          <input type="text" class="browser-default" />
        </div>
        <div class="col s4">
          <label>Email Id</label>
          <input type="text" class="browser-default" />
        </div>
        <div class="col s4">
          <label>Mobile Number</label>
          <input type="text" class="browser-default" />

        </div>
      </div>
      <div class="row">
        <div class="col s4">
          <label>Batch</label>
          <select class="browser-default rounded">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
        <div class="col s4">
          <label>Role</label>
          <select class="browser-default rounded">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
        <div class="col s4">
          <label>Student type</label>
          <select class="browser-default rounded">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <a class="waves-effect  btn ">Generate Report</a>
    </div>
    <div class="col s12">
      <table class="qtbl centered highlight">
        <thead>
          <tr>
            <th>Name</th>
            <th>Course</th>
            <th>Email</th>
            <th>CenterCode</th>
            <th>Last Login</th>
            <th>Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>Course</td>
            <td>Email</td>
            <td>CenterCode</td>
            <td>Last Login</td>
            <td>Expiry</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

};

</script>

<style scoped>
  .content {
    margin-top: 50px;
  }

  .btn {
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    color: white;

  }

  table.qtbl {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 1176px;
  }

  table.qtbl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }

  table.qtbl thead tr th {
    border-radius: 0;
  }

  table.qtbl thead tr th:first-child,
  table.qtbl tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
  }

  table.qtbl tbody tr {
    cursor: pointer;
    background-color: #fff;
  }

</style>
